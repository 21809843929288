// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap";
import "../stylesheets/application.scss";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// eslint-disable-next-line import/no-unresolved
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// eslint-disable-next-line no-unused-vars
const images = require.context("../images", true);
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
